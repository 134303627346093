import React from 'react'
import css from './Footer.module.css';
import Logo from '../../assets/logo.png';
import {InboxIcon, PhoneIcon, LocationMarkerIcon, LoginIcon, UsersIcon, LinkIcon} from "@heroicons/react/outline";
import {CloudIcon, RssIcon, ClipboardCopyIcon} from "@heroicons/react/outline";
const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
      <hr/>
      <div className={css.cFooter}>
        <div className={css.logo}>
          <img src={Logo} alt=""/>
          <span>amazon</span>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Contact Us</span>
            <span className={css.pngLine}>
              <LocationMarkerIcon className={css.icon}/>
              <span>111 north avenue Orlado, FL 32801</span>
            </span>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Account</span>
            <span className={css.pngLine}>
              <CloudIcon className={css.icon}/>
              <span>Sign in</span>
            </span>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Company</span>
            <span className={css.pngLine}>
              <ClipboardCopyIcon className={css.icon}/>
              <span>About us</span>
            </span>
          </div>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Resources</span>
            <span className={css.pngLine}>
              <RssIcon className={css.icon}/>
              <span>Safety Privacy & Terms</span>
            </span>
          </div>
        </div>
      </div>
      <div className={css.copyRight}>
        <span>Copyright 2022 by Nikita Selyaninov</span>
        <span>All rights reserved.</span>
      </div>
    </div>
  )
}

export default Footer